body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-left-text-wrapper {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    padding: 80px 40px;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}
html {
    width: 100%;
}

body {
    overflow-x: hidden !important;
}

body.show-spinner > main {
    overflow: hidden !important;
}

/* Hide everything under body tag */
body.show-spinner > * {
    opacity: 0;
}

/* Spinner */
body.show-spinner::after {
    content: " ";
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border-top-color: rgba(0, 0, 0, 0.3);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: fixed;
    z-index: 1;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.line-height-1 {
    line-height: 1;
}

/*Pagination Css*/
.pagination_with_button_number {
    display: inline-block;
}
.datatable-search-data {
    position: relative;
}

span.datatable-search-icon {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
    height: 16px;
}

.datatable-search-data input {
    border-radius: 8px;
}
