.login-left-text-wrapper {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    padding: 80px 40px;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}